<template>
<div>
    <v-row justify="center">
        <v-col cols="12">
            <p class="subheading font-weight-regular">
        {{$t('contribution_amt')}}
        </p>
         <p class="font-weight-bold">
       ${{$store.getters.getEventDetails[0].ticket_price.toFixed(2)}} <small class="ml-1">CAD</small>
        </p>
        <div>
        </div>
          <v-row v-if="!soldOut" justify="center" class="ma-0">
          <v-btn icon class="mt-5" @click="decreseQuantity">
           <v-icon>mdi-minus</v-icon>
           </v-btn>
        <v-col cols="3">
            <v-text-field outlined v-model="quantity" :label="$t('quantity')" readonly class="quantity-input"></v-text-field>

        </v-col>
        <v-btn icon class="mt-5" @click="increseQuantity">
             <v-icon>mdi-plus</v-icon>
              </v-btn>
        </v-row>
    <v-col cols="12">
   <v-alert
   v-model="soldOut"
      dense
      outlined
      type="error"
    >
     {{$t('sold_out')}}
    </v-alert>
   <v-alert
      v-model="alert"
      dismissible
      color="#E9A336"
      border="left"
      elevation="2"
      colored-border
    >
     {{$t('buying_all')}}
    </v-alert>
    </v-col>
        <v-row justify="center" class="ma-0">
   <v-col>
     <p>{{$t('total_amt')}}: <span class="display-2 font-weight-regular ml-2">${{displayTotal}}</span>  CAD</p>
     <small>{{$t('tax_note')}}</small>
     </v-col>
        </v-row>
        </v-col>

        <v-col cols="12">
          <v-text-field outlined :label="$t('booking_name')" v-model="bookingName" :rules="[rules.required, rules.counter]"></v-text-field>
         <small v-if="bookingName.length>1" style="color:red">{{$t('click_outside')}}</small>
           <v-stripe-card
           outlined
    v-model="token"
    :api-key="pbKey"
  ></v-stripe-card>

        </v-col>
    </v-row>
    <!-- Loader Box section start -->
    <v-dialog v-model="loader" hide-overlay persistent width="300">
      <v-card color="secondary">
        <v-card-text class="text-center primary--text">
          {{$t('processing')}}
          <v-progress-linear rounded indeterminate color="primary" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Loader Box section end -->
    </div>
</template>
<script>
import { fstore } from '@/plugins/firebase'
export default {

  data: () => ({
    token: null,
    pbKey: process.env.VUE_APP_STRIPE_API_KEY,
    cardInfo: '',
    loader: false,
    quantity: 1,
    bookingName: '',
    alert: false,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 50 || 'Keep it short and sweet.'
    }
  }),
  watch: {
    token: function (val) {
      this.cardInfo = val
      this.$store.dispatch('enableConfirmAction')
    },
    // !! for testing loading UI !!
    // loader (val) {
    //   if (!val) return
    //   setTimeout(() => {
    //     this.loader = false
    //     this.$emit('last-step')
    //   }, 3000)
    // }
    bookingName: function (val) {
      if (val.length > 0) {
        this.$emit('booking-name')
      }
    },
    quantity: function (val) {
      if (val > this.$store.getters.getEventDetails[0].event_capacity) {
        this.quantity = this.$store.getters.getEventDetails[0].event_capacity
        this.alert = true
      }
    }
  },
  computed: {
    localDate () {
      var date = new Date().toLocaleDateString()
      return date
    },
    localTime () {
      var time = new Date().toLocaleTimeString()
      return time
    },
    displayTotal () {
      const tot = (this.quantity * this.$store.getters.getEventDetails[0].ticket_price).toFixed(2)
      return tot
    },
    totalAmt () {
      const amt = Math.round(Number(this.quantity * this.$store.getters.getEventDetails[0].ticket_price) * 100)
      return amt
    },
    soldOut () {
      if (this.$store.getters.getEventDetails[0].event_capacity === 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    increseQuantity () {
      this.quantity++
    },
    decreseQuantity () {
      if (this.quantity > 1) {
        this.quantity--
      }
    },
    saveTransaction () {
      // charge object
      const charge = {}
      charge.id = this.cardInfo.id
      charge.clientIp = this.cardInfo.client_ip
      charge.totalAmt_entered = this.displayTotal
      charge.totalContribution = this.totalAmt
      charge.description = `${this.quantity} ticket(s) for-${this.bookingName}`
      charge.payed_on = this.localDate
      charge.payed_at = this.localTime
      charge.soldTickets = this.quantity
      charge.eventId = this.$store.getters.getEventDetails[0].event_id
      this.loader = true
      // console.log('Constructed charge is :', charge)
      // save the transaction info to database
      const ref = fstore.collection('cabaret_events').doc(charge.eventId).collection('transactions').doc()
      const id = ref.id
      const payload = {
        eventId: charge.eventId,
        transactionId: id
      }
      this.$store.dispatch('bindEventTransactionCollection', payload)
      fstore
        .collection('cabaret_events')
        .doc(charge.eventId)
        .collection('transactions')
        .doc(id)
        .set(charge)
        .then(() => {
          this.$store.dispatch('disableConfirmAction')
          fstore
            .collection('cabaret_events')
            .doc(charge.eventId)
            .collection('transactions')
            .where('eventId', '==', charge.eventId)
            .onSnapshot((querySnap) => {
              var response = [] // <-- reset the array here
              querySnap.docChanges().forEach(change => {
                if (change.type === 'modified') {
                  this.loader = false
                  response.push(change.doc.data())
                  // emit an event when a response is registered
                  this.$emit('last-step')
                }
              })
              // set response in the store
              this.$store.dispatch('stripeEventResponseAction', response)
            })
        }).catch(err => {
          // this.errMsg = err
          this.$store.dispatch('errMsgAction', err)
        })
    }
  }
}
</script>
<i18n>
{
  "en":{
    "confirm_amt":"Please enter your payment card details",
    "contribution_amt":"Contribution Amount",
    "booking_name":"Name for your booking*",
    "quantity":"Quantity",
    "total_amt":"Total",
    "tax_note":"All applicable taxes are included. Contact us via email for valid refunds.",
    "click_outside":"click outside the credit card box after entering the card number to confirm.",
    "sold_out":"Sorry, this event is sold out.",
    "buying_all":" You are going to be reserving all of the remaining spots!!",
    "reenter_card1":"To make another donation, ",
    "reenter_card_bold":"re-enter ",
    "reenter_card2":"your credit card number and click outside the box to confirm.",
    "processing":"Processing payment"
  },
  "fr":{
    "confirm_amt":"Veuillez entrer vos informations de carte de crédit",
    "contribution_amt":"Montant de contribution",
    "booking_name":"Nom de votre réservation*",
    "quantity":"Quantité",
    "total_amt":"Total",
    "tax_note":"Tous les impôts sont inclus. Contactez-nous par email pour des remboursements valides.",
    "click_outside":"pour confirmer, cliquez en dehors de la boîte de carte de crédit.",
    "sold_out":"Désolé, cet événement est complet.",
    "buying_all":"Vous allez réserver toutes les places restantes!!",
    "reenter_card1":"Pour faire un autre don, ",
    "reenter_card_bold":"entrez à nouveau ",
    "reenter_card2":"votre numéro de carte de crédit et cliquez en dehors de la boîte pour confirmer.",
    "processing":"Paiement en cours"
  },
  "es":{
    "confirm_amt":"Introduzca los datos de su tarjeta de crédito",
    "contribution_amt":"Cantidad de contribución",
    "booking_name":"Nombre de su reserva*",
    "quantity":"Cantidad",
    "total_amt":"Total",
    "tax_note":"Todos los impuestos incluidos. Contacte con nosotros por email para devoluciones válidas.",
    "click_outside":"para confirmar, haga clic fuera de la casilla de la tarjeta de crédito",
    "sold_out":"Lo sentimos, este evento está completo.",
    "buying_all":"Usted está reservando todas las plazas restantes!!",
    "reenter_card1":"Si está haciendo una segunda donación, ",
    "reenter_card_bold":"introduzca de nuevo ",
    "reenter_card2":"su número de tarjeta de crédito y haga clic fuera de la casilla para confirmar.",
    "processing":"Procesando pago"
  }
}
</i18n>
<style scoped>
.quantity-input >>> input {
  text-align: center;
}
</style>
